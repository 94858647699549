import { DocumentType, NoteType } from '@innedit/innedit-type';
import { NoteData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

// import IconPlus from '~/icons/Plus';
import ListItem from './index';

const ListItemNote: FC<ListItemProps<NoteType, NoteData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<NoteType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const description =
    doc.description && doc.description.length > 250
      ? `${doc.description.slice(0, 250)}...`
      : doc.description;

  return (
    <ListItem
      // actions={[
      //   {
      //     icon: IconPlus,
      //     label: 'Ajouter une sous-note',
      //     to: `/espaces/${doc.espaceId}/notes/create?parent=${docId}`,
      //   },
      // ]}
      className={className}
      description={description}
      displayActionId={false}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemNote;
